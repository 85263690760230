<template>

  <div>
    <!--탑메뉴-->
    <topbar-comp></topbar-comp>
    <left-bar-comp>
      <sports-left-bar-comp></sports-left-bar-comp>
    </left-bar-comp>

    <div class="content_section">
      <div class="content">
        <div class="holdemwarper pc">
          <img src="../../assets/images/casino/holdem-pc.jpg"  alt="" @click="loginRevoHoldem">
        </div>
        <div class="holdemwarper mobile">
          <img src="../../assets/images/casino/holdem-mobile.jpg"  alt="" @click="loginRevoHoldem">
        </div>
      </div>
    </div>

    <!-- right 메뉴 -->
    <right-bar-comp>
      <!--내정보-->
      <user-info-comp slot="userinfo"></user-info-comp>
      <right-buttons-comp slot="btns"></right-buttons-comp>
      <!--배팅카트-->
      <sports-bet-cart-comp slot="betcart"></sports-bet-cart-comp>
      <right-bar-banner-comp slot="banner"></right-bar-banner-comp>
    </right-bar-comp>

    <!--위로가기-->
    <scroll-top-comp></scroll-top-comp>
    <foot-comp></foot-comp>

  </div>


</template>

<script>
import RightBarComp from "../../components/RightBarComp";
import LeftBarComp from "../../components/LeftBarComp";
import LeisureLeftComp from "../../components/leisuregame/LeisureLeftComp";
import ScrollTopComp from "../../components/ScrollTopComp";
import SubTitle from "../../components/SubTitle";
import TopbarComp from "../../components/TopbarComp";
import FootComp from "../../components/FootComp";
import RightBarBannerComp from "../../components/RightBarBannerComp";
import {
  addMemberCasinoCash, addMemberCasinoCash2,
  getAccountBalance, getAccountBalance2, getCasinoMaintaining, getGameLancher, getGameLancher2,
  getGameList, subjectMemberCasinoCash, subjectMemberCasinoCash2
} from "../../network/casinoHonorRequest";
import SportsLeftBarComp from "../../components/sports/SportsLeftBarComp";
import sportsConst from "../../common/sportsConst";
import SportsBetCartComp from "../../components/sports/SportsBetCartComp";
import {RECEIVE_HIDE_LOADING, RECEIVE_SHOW_LOADING} from "../../store/mutation-types";
import {postionMixin} from "../../common/mixin";
import UserInfoComp from "../../components/UserInfoComp";
import RightButtonsComp from "../../components/RightButtonsComp";
import {getRevoHoldemLoginUrl} from "@/network/revoHoldemRequest";

export default {
  name: "RevoHoldem",
  mixins: [postionMixin],
  components: {
    RightButtonsComp,
    UserInfoComp,
    SportsBetCartComp,
    SportsLeftBarComp,
    RightBarBannerComp,
    FootComp, TopbarComp, SubTitle, ScrollTopComp, LeisureLeftComp, LeftBarComp, RightBarComp
  },
  data() {
    return {
      position: "홀덤",
    }
  },
  methods: {
    loginRevoHoldem() {
      this.$store.commit(RECEIVE_SHOW_LOADING)
      getRevoHoldemLoginUrl().then(res => {
        if (res.data.success) {
          let url = res.data.data;
          window.open(url, '_blank')
        } else {
          this.$swal({
            title: res.data.msg,
            type: 'error',
            showCancelButton: false,
            showConfirmButton: true
          })
        }
        this.$store.commit(RECEIVE_HIDE_LOADING)
      })
    }
  },
  created() {

  }
}
</script>

<style scoped>
@import url("../../assets/css/base.css");
@import url("../../assets/css/common.css");
@import url("../../assets/css/style.css");
@import url("../../assets/css/media.css");
@import url("../../assets/css/sports.css");

.holdemwarper {
  width: 100%;
}
.pc{
  display: block;
}
.mobile{
  display: none;
}

.holdemwarper img {
  width: 100%;
  cursor: pointer;
}

@media screen and (max-width: 1024px) {
  .pc{
    display: none;
  }
  .mobile{
    display: block;
  }
}


</style>